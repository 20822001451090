import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: {
      title: "登录 - 乐享管理平台",
    },
  },
  {
    path: "/admin",
    name: "adminLayout",
    component: () => import("../layout/AdminLayout.vue"),
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("../views/admin/IndexView.vue"),
        meta: {
          title: "首页 - 乐享管理平台",
          tabTitle: "控制台",
          icon: "House",
          isTab: true,
        },
      },
      {
        path: "set",
        name: "set",
        component: () => import("../views/admin/SetView.vue"),
        meta: {
          title: "基础设置 - 乐享管理平台",
          tabTitle: "基础设置",
          icon: "Setting",
          isTab: true,
        },
      },
      {
        path: "user",
        name: "user",
        component: () => import("../views/admin/UserView.vue"),
        meta: {
          title: "用户管理 - 乐享管理平台",
          tabTitle: "用户管理",
          icon: "User",
          isTab: true,
        },
      },
      {
        path: "project",
        name: "project",
        component: () => import("../views/admin/ProjectView.vue"),
        meta: {
          title: "项目管理 - 乐享管理平台",
          tabTitle: "项目管理",
          icon: "Files",
          isTab: true,
        },
      },
      {
        path: "message",
        name: "message",
        component: () => import("../views/admin/MessageView.vue"),
        meta: {
          title: "留言管理 - 乐享管理平台",
          tabTitle: "留言管理",
          icon: "ChatLineSquare",
          isTab: true,
        },
      },
      {
        path: "add",
        name: "add",
        component: () => import("../views/admin/addView.vue"),
        meta: {
          title: "添加项目 - 乐享管理平台",
          isTab: false,
        },
      },
    ],
  },
  { path: "/error", component: () => import("../views/ErrorView.vue") },
  {
    path: "/:pathMatch(.*)",
    redirect: "/error",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title as string;
  }
  const isLogin = localStorage.getItem("token");
  isLogin && to.name === "login"
    ? next({ name: "index" })
    : isLogin || to.name === "login"
    ? next()
    : next({ name: "login" });
});

export default router;
