<template>
  <router-view />
</template>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

body::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 2px;
  background: rgb(22, 119, 255);
}

body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

body::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;

  /*滚动条里面轨道*/
}

a {
  text-decoration: none;
  cursor: pointer;
}
.router-link-active {
  text-decoration: none;
}

.lx-admin-content {
  background: #fff;
  padding: 20px;
}

.lx-page-numebr {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
